import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "typeface-roboto";
import Navigation from "./Navigation";
import ServerProvider from "./ServerProvider";
import ErrorHandler from "./common/components/ErrorHandler";
import i18n from "./common/components/LocalizationProvider";
import NativeInterface from "./common/components/NativeInterface";
import theme from "./common/theme";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store from "./store";
import SubscriptionController from "./SubscriptionController";
import "./styles.css";

const rootElement = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ServerProvider>
            <BrowserRouter>
              <Navigation />
              <SubscriptionController />
            </BrowserRouter>
          </ServerProvider>
          <ErrorHandler />
          <NativeInterface />
        </ThemeProvider>
      </StyledEngineProvider>
    </I18nextProvider>
  </Provider>
);

serviceWorkerRegistration.register();
