import React from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import APIWrapper from "../../../APIWrapper/APIWrapper";
import { SignOut } from "../../../assets/svgs";
import RightSidebarHeader from "../../../common/components/RightSidebarHeader";
import {
  dataActions,
  devicesActions,
  geofencesActions,
  groupsActions,
  positionsActions,
  selectorActions,
  sessionActions,
} from "../../../store";
import { subscriptionActions } from "../../../store/subscriptions";
import { SettingsChildRoutes } from "./SettingsChildRoutes";

const SettingsMenu = () => {
  const path = useLocation();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.session.user, shallowEqual);

  const subscriptionsActive = useSelector(
    (state) => !!state.subscriptions.active
  );

  const showSelfServeMenus =
    user.administrator === true ||
    (user.deviceLimit > 0 &&
      (user.attributes?.customerOwner === "true" ||
        user.attributes?.customerOwner === true));

  const handleLogout = async () => {
    const notificationToken = window.localStorage.getItem("notificationToken");
    if (notificationToken) {
      window.localStorage.removeItem("notificationToken");

      const tokens = user.attributes.notificationTokens?.split(",") || [];
      if (tokens.includes(notificationToken)) {
        const updatedUser = {
          ...user,
          attributes: {
            ...user.attributes,
            notificationTokens: tokens
              .filter((it) => it !== notificationToken)
              .join(","),
          },
        };

        await APIWrapper.HttpModule().put(`/api/users/${user.id}`, {
          data: updatedUser,
        });
      }
    }

    dispatch(sessionActions.updateUser(null));
    dispatch(dataActions.clear({}));
    dispatch(positionsActions.reset([]));
    dispatch(devicesActions.reset([]));
    dispatch(groupsActions.reset([]));
    dispatch(geofencesActions.reset([]));
    dispatch(selectorActions.reset({}));
    dispatch(subscriptionActions.reset({}));

    window.localStorage.removeItem("rangr_token");
    window.localStorage.removeItem("mapTypeId", false);
    window.localStorage.clear();

    localStorage.setItem("reloaded", true);

    await APIWrapper.HttpModule().delete("/api/session");
    await APIWrapper.HttpModule().WebsocketClose();
  };

  let routeItems = SettingsChildRoutes;
  if (!subscriptionsActive) {
    routeItems = SettingsChildRoutes.filter((item) => {
      item.pathname.includes("subscription");
    });
  }

  return (
    <div data-testid="settingsMenu" className="h-full w-full font-normal">
      <div className="lg:hidden mb-4">
        <RightSidebarHeader
          testId="settingsMenuSidebar"
          onClick={() =>
            dispatch(
              selectorActions.setSidebarsState({
                name: "settingsMenuSidebar",
                value: false,
              })
            )
          }
          Title={t("settings")}
        />
      </div>

      {routeItems.map(
        (item, i) =>
          item?.show?.includes(showSelfServeMenus) && (
            <Link
              key={i}
              to={item?.pathname}
              className={`myAccountStep-${i + 1}`}
            >
              <div
                data-testid={`${"LinkCrossIcon" + i}`}
                onClick={() =>
                  dispatch(
                    selectorActions.setSidebarsState({
                      name: "settingsMenuSidebar",
                      value: false,
                    })
                  )
                }
                className={`${
                  item.pathname.includes(path?.pathname)
                    ? " bg-white shadow-md shadow-[#F07023] border-b-4 border-r-4 border-[#F07023]"
                    : " bg-white bg-opacity-30  shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)]"
                } mb-2 w-full rounded-lg hover:bg-orange-300 flex  h-fit  p-4 gap-5 cursor-pointer items-center`}
              >
                <span>{item?.icon}</span>
                <span>{t(item?.title)}</span>
              </div>
            </Link>
          )
      )}
      <div
        onClick={handleLogout}
        className="myAccountStep-4 mb-1 w-full rounded-lg bg-white bg-opacity-30 hover:bg-orange-200 flex  h-fit p-4 gap-5 cursor-pointer items-center shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)]"
      >
        <span>{SignOut}</span>
        <span>{t("signOut")}</span>
      </div>
    </div>
  );
};

export default SettingsMenu;
